Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.httpGetMethod = "GET";
exports.httpPostMethod = "POST";
exports.httpPutMethod = "PUT";
exports.httpDeleteMethod = "DELETE";
exports.searchApiContentType = "application/json";
exports.updateProfileContentType = "multipart/form-data";
exports.getSearchApiEndPoint = "search/users";
exports.errorTitle = "Error";
exports.changePasswordEndPoint = "change_password";
exports.deleteAccountEndPoint = "delete_account";
exports.deactivateAccountEndPoint = "profiles/deactivate_account";
exports.getCustomizableProfileEndPoint = "profiles";
exports.updateCustomizableProfileEndPoint = "profile_update";
exports.getNotificationPreferencesEndPoint = "bx_block_settings/show_settings";
exports.updateNotificationPreferencesEndPoint = "bx_block_settings/update_notification";
exports.getCustomizableProfileFieldsEndPoint =
  "bx_block_profile/profile/custom_user_profile_fields";
exports.getCustomisableProfileEndPoint =  "show_account";
exports.deactivateAccountPopoverTitle = "Are you sure you want to deactivate your account?";
exports.deactivateAccountPopoverSubTitle = "Do you want to log out of your account instead?";
exports.deactivateAccountPopoverPrimaryButtonText = "Deactivate account"
exports.deleteAccountPopoverTitle = "Are you sure you want to delete your account?";
exports.deleteAccountPopoverSubTitle = "All your information will be deleted from Hometown Maps. Do you want to log out of your account instead?";
exports.deleteAccountPopoverPrimaryButtonText = "Delete account"
exports.deleteAccountPopoverPrimaryButtonColor = "#F87171"
exports.deleteAccountPopoverSecondaryButtonText = "Logout"
exports.deleteAccountPopoverIconColor = "#8861A9"

exports.logoutAccountPopoverTitle = "Are you sure you want to log out of your account?";
exports.logoutAccountPopoverPrimaryButtonText = "Yes, proceed"
exports.logoutAccountPopoverPrimaryButtonColor = "#8833FF"
exports.logoutAccountPopoverSecondaryButtonText = "Cancel"

exports.changePasswordPopoverTitle = "Are you sure you want to change your password?";
exports.changePasswordPopoverPrimaryButtonText = "Yes, proceed"
exports.changePasswordPopoverPrimaryButtonColor = "#8833FF"
exports.changePasswordPopoverSecondaryButtonText = "Cancel"

exports.errorBlankField = "All fields are mandatory.";
// Customizable Area End
