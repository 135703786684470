import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { setStorageData, getStorageData } from "../../../framework/src/Utilities";
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";

type Plan = {
  id: string;
  type: string;
  attributes: {
    name: string;
    yearly_price: string;
    monthly_price: string;
    description: string;
    sub_title: string;
    subscription_type: "yearly" | "monthly" | "weekly";
    valid_up_to: string;
    expired: boolean;
    image_link: string | null,
    subscribed: boolean;
    plan_type: "Basic" | "Premium" | "Founder" | "Event" | "User Plan";
  }
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  token: string;
  plan: Plan;
  selectedSubscriptionPaymentType: "monthly" | "yearly";
  isFreetrial: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class Subscriptionbilling2Controller extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  proceedToPayCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Sta.rt
    // Customizable Area End
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIRequestMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      token: "",
      plan: {
        id: "",
        type: "",
        attributes: {
          name: "",
          yearly_price: "",
          monthly_price: "",
          description: "",
          sub_title: "",
          subscription_type: "yearly",
          valid_up_to: "",
          expired: false,
          image_link:"",
          subscribed: false,
          plan_type: "User Plan",
        }
      },
      selectedSubscriptionPaymentType: "monthly",
      isFreetrial:false,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      let token = message.getData(getName(MessageEnum.SessionResponseToken));

      this.setState({ token: token });
    }

    if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
      const { plan, isFreetrial } = message.getData(
        getName(MessageEnum.SessionResponseData)
      );

      
      if (plan.attributes.monthly_price && plan.attributes.monthly_price !== "0.0") {
        this.setState({ 
          selectedSubscriptionPaymentType: "monthly"
        });
      } else {
        this.setState({ 
          selectedSubscriptionPaymentType: "yearly"
        });
      }

      this.setState({ 
        plan: plan,
        isFreetrial
      });
    }

    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      const errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (apiRequestCallId && responseJson && !errorReponse) {
        if (apiRequestCallId === this.proceedToPayCallId) {
          window.location.replace(responseJson.stripe_session.url)
        } 
      }
    }
    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let message = new Message(getName(MessageEnum.AccoutLoginSuccess));
    message.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(message);
  }

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
    this.getStoragePlanInformation();
    this.getToken();
  }

  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };

  goToCustomisableUserSubscriptions() {
    const message: Message = new Message(
      getName(MessageEnum.NavigationCustomisableUserSubscriptionsMessage)
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  }

  saveInformationOnLocalStorage = () => {
    setStorageData("authtoken", this.state.token);
    setStorageData("selectedPlan", JSON.stringify(this.state.plan));
    setStorageData("selectedSubscriptionPaymentType", this.state.selectedSubscriptionPaymentType)
  }

  getStoragePlanInformation = async () => {
    const token = await getStorageData("authtoken");
    const selectedSubscriptionPaymentType = await getStorageData("selectedSubscriptionPaymentType");
    const plan = await getStorageData("selectedPlan", true);  

    if (token && plan && selectedSubscriptionPaymentType) {
      this.setState({
        token,
        plan,
        selectedSubscriptionPaymentType
      })
  
      const msg: Message = new Message(getName(MessageEnum.SessionSaveMessage));
  
      msg.addData(
        getName(MessageEnum.SessionResponseToken),
        token
      );
  
      this.send(msg);
    }
  }

  proceedToPay = () => {
    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      token: this.state.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.proceedToPayCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      this.state.isFreetrial ? configJSON.startFreeTrial : configJSON.proceedToPayEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify({
        subscription_id: this.state.plan.id,
        success_url: window.location.origin + `/SuccessSubscriptionbilling2?subscription_id=${this.state.plan.id}&subscription_name=${this.state.plan.attributes.name}`,
        cancel_url: window.location.origin + "/Subscriptionbilling2",
        subscription_type: this.state.selectedSubscriptionPaymentType,
        price: this.state.selectedSubscriptionPaymentType === "monthly" ? this.state.plan.attributes.monthly_price : this.state.plan.attributes.yearly_price
      })
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.proceedToPayAPiMethod
    );

    this.saveInformationOnLocalStorage();

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  // Customizable Area End
}
