import React from "react";

// Customizable Area Start
import { View, StyleSheet, TouchableOpacity, ScrollView, Pressable } from 'react-native'
import { Typography } from "@builder/component-library";
import CloseIcon from '@mui/icons-material/CloseOutlined';
import ArrowLeftIcon from '@mui/icons-material/ArrowBackIosNewOutlined';
import ArrowRightIcon from '@mui/icons-material/ArrowForwardIosOutlined';
// Customizable Area End

import GalleryController, {
  Props,
  configJSON,
} from "./GalleryController";



export default class Gallery extends GalleryController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <View style={styles.galleryScreen}>
        <View style={styles.galleryContainer}>
          <View style={styles.galleryHeader}>
            <Typography variant="2xl"  style={styles.galleryTitle}>Gallery</Typography>

            <TouchableOpacity testID="btnCloseGallary" onPress={this.props.onCloseGallery}>
              <CloseIcon sx={styles.galleryIcon} />
            </TouchableOpacity>
          </View>

          <View style={styles.gallery}>
            <TouchableOpacity 
              testID="btnPrevPhoto"
              disabled={this.state.selectedPhotoIndex <= 0} 
              onPress={() => this.handleChangeSelectedPhoto(this.state.selectedPhotoIndex - 1)}
            >
              <ArrowLeftIcon sx={styles.galleryIcon} />
            </TouchableOpacity>

            <View style={styles.photosContainer}>
              <img 
                src={this.state.photos[this.state.selectedPhotoIndex]} 
                style={styles.mainImage}
              />
              
              <ScrollView horizontal={true} style={styles.footerGallery}>
                {
                  this.state.photos.map((photo, i) =>  {
                    return (
                      <Pressable 
                        testID={"btnSelectPhoto-"+i}
                        key={i} 
                        onPress={() => this.handleChangeSelectedPhoto(i)} style={styles.secondaryImageContainer}
                      >
                        <img style={this.state.selectedPhotoIndex === i ? styles.secondaryImageSelected : styles.secondaryImage} src={photo} />
                        {
                          this.state.selectedPhotoIndex !== i && (
                            <View style={styles.secondaryImageOverlay} />
                          )
                        }
                      </Pressable>
                    )
                  })
                }
              </ScrollView>
            </View>

            <TouchableOpacity 
              testID="btnNextPhoto"
              disabled={this.state.selectedPhotoIndex + 1 >= this.state.photos.length} 
              onPress={() => this.handleChangeSelectedPhoto(this.state.selectedPhotoIndex + 1)}
            >
              <ArrowRightIcon sx={styles.galleryIcon} />
            </TouchableOpacity>
          </View>
        </View>
      </View>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const styles = StyleSheet.create({
  galleryScreen: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 1,
    width: "100vw",
    height: "100vh",
    paddingVertical: 83,
    paddingHorizontal: 150,
  },
  galleryContainer: {
    width: "100%",
    height: "100%",
    backgroundColor: "#000000",
    zIndex: 2,
    position: "relative",
    paddingHorizontal: 40,
    paddingVertical: 28
  },
  galleryHeader: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between"
  },
  galleryTitle: {
    fontWeight: "700",
    color: "#FFFFFF"
  },
  galleryIcon: {
    fontSize: 24,
    color: "#FFFFFF"
  },
  mainImage: {
    width: 579,
    maxHeight: 542
  },
  secondaryImageContainer: {
    position: "relative",
    width: 83,
    height: 78,
    marginRight: 36,
  },
  secondaryImage: {
    width: "100%",
    height: "100%",    
    borderRadius: 12,
  },
  secondaryImageSelected: {
    width: "100%",
    height: "100%",    
    borderRadius: 12,
    borderWidth: 2,
    borderColor: "#9BCE7B",
    borderStyle: "solid",
  },
  secondaryImageOverlay: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  },
  gallery: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    paddingHorizontal: 150,
    marginTop: 40,
  },
  photosContainer: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center"
  },
  footerGallery: {
    maxWidth: "100%",
    marginTop: 36,
  }
});
// Customizable Area End
