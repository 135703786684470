import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import React, { createRef } from "react";
import { ScrollView } from "react-native";
const config = require("../../../framework/src/config");
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  isPremium: boolean;
  loading: boolean;
  alertVisibility: boolean;
  enablePasswordField: boolean;
  alertMessage: string;
  account_id: number | null;
  fullName: string;
  email: string;
  street: string;
  city: string;
  state: string;
  country: string;
  zipcode: string;
  bio: string;
  facebook: string;
  instagram: string;
  operatingHours: {
    [key: string]: {
      open: boolean;
      start_hour: Date | null,
      end_hour: Date | null
    }
  };
  linkWebsite: string;
  password: string;
  uploadedPictures: string[];
  uploadedFiles: Blob[];
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class UpdateBusinessInformationController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  updateBusinessInfoScrollRef = createRef<ScrollView>();
  inputProfilePictureRef: React.RefObject<HTMLInputElement>;
  token: string = "";
  updateBusinessInformationCallId: string = "";
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceDataMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage)
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      isPremium: false,
      enablePasswordField: true,
      loading: false,
      alertVisibility: false,
      alertMessage: "",
      account_id: null,
      fullName: "",
      email: "",
      street: "",
      city: "",
      state: "",
      country: "",
      zipcode: "",
      operatingHours: {
        sunday: {
          open: false,
          start_hour: null, 
          end_hour: null
        },
        monday: {
          open: false,
          start_hour: new Date(), 
          end_hour: new Date()
        },
        tuesday: {
          open: false,
          start_hour: new Date(), 
          end_hour: new Date()
        },
        wednesday: {
          open: false,
          start_hour: new Date(), 
          end_hour: new Date()
        },
        thursday: {
          open: false,
          start_hour: new Date(), 
          end_hour: new Date()
        },
        friday: {
          open: false,
          start_hour: new Date(), 
          end_hour: new Date()
        },
        saturday: {
          open: false,
          start_hour: new Date(), 
          end_hour: new Date()
        },
      },
      linkWebsite: "",
      password: "",
      uploadedPictures: [],
      uploadedFiles: [],
      bio: "",
      facebook: "",
      instagram: "",
      // Customizable Area End
    };
    // Customizable Area Start
    this.inputProfilePictureRef = React.createRef();
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
    // Customizable Area Start
    // Customizable Area End
  }

  getToken = () => {
    const message: Message = new Message(getName(MessageEnum.SessionRequestMessage));
    this.send(message);
  };

  async receive(from: string, message: Message) {
    // Customizable Area Start
    const isSessionResponseMessage = getName(MessageEnum.SessionResponseMessage) === message.id;
    const isRestAPIResponseMessage = getName(MessageEnum.RestAPIResponceMessage) === message.id;

    this.handleNavigationPayloadMessage(message);

    if (isSessionResponseMessage) {
      this.handleSessionResponseUpdateBusinessInformation(message);
    }

    if (isRestAPIResponseMessage) {
      this.handleAPIResponse(message);
    }
    // Customizable Area End
  }

  // Customizable Area Start
  private handleSessionResponseUpdateBusinessInformation(message: Message) {
    const sessionToken = message.getData(getName(MessageEnum.SessionResponseToken));
    if (!sessionToken || sessionToken === "null") {
      this.token = "eyJhbGciOiJIUzUxMiJ9.eyJpZCI6NDY3LCJleHAiOjE2NzkwNDI3NTAsInRva2VuX3R5cGUiOiJsb2dpbiJ9.zi-zzVPBT-4TQm8cQK8uK6qsoDqTIL_rT-LZX1sptxseOUYhWeHtibNbbmdRUJTSz2THXUUuNlBfkIH6QCFGgg";
    } else {
      this.token = sessionToken
    }

    const testToken = window.localStorage.getItem("testToken");

    if (testToken) {
      this.token = testToken;
    }

    runEngine.debugLog("TOKEN1", this.token);
  }

  private handleAPIResponse(message: Message) {
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

    this.setState({
      loading: false
    })

    if (responseJson && !responseJson.errors) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));

      if (apiRequestCallId === this.updateBusinessInformationCallId) {
        this.navigateToProfile();
      }
    } else {
      this.showAlert(
        "Error",
        responseJson.errors[0] || "Something went wrong!"
      )
    }
  }

  private handleNavigationPayloadMessage(message: Message) {
    if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
      const { account_id, street, city, state, country, email, fullName, linkWebsite, operatingHours, zipCode, bio, facebook, instagram, isPremium, images } = message.getData(getName(MessageEnum.NavigationBusinessInformationMessage));

      this.setState({
        account_id,
        street,
        city,
        state,
        country,
        email,
        fullName,
        linkWebsite,
        operatingHours,
        zipcode: zipCode,
        bio,
        facebook,
        instagram,
        isPremium,
        uploadedPictures: images.map((image: string) => `${config.baseURL}${image}`),
      })

      this.fetchImagesAsBlob(images);
    }
  }

  async fetchImagesAsBlob(images: string[]) {
    for (const image of images) {
      const response = await fetch(config.baseURL + image)
      const blob = await response.blob();
      this.setState({
        uploadedFiles: [...this.state.uploadedFiles, blob]
      })
    }
  }

  showAlert(alertType: string, alertMessage: string) {
    if (this.updateBusinessInfoScrollRef.current) {
      this.updateBusinessInfoScrollRef.current.scrollTo({
        y: 0,
        animated: true
      })
    }

    this.setState({
      alertVisibility: true,
      alertMessage: alertMessage
    })
  }

  handleSelectedFile = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;

    if (files) {
      const MAX_SIZE_MB = 5 * 1024 * 1024;
      const MAX_FILES = this.state.isPremium ? 5 : 3;
      const currentPictures = this.state.uploadedPictures.length;

      const validFiles = Array.from(files).filter(file => file.size <= MAX_SIZE_MB);

      if (validFiles.length === 0) {
        this.showAlert("Error", "Each file must be no larger than 5MB.");
        return;
      }

      const remainingFiles = MAX_FILES - currentPictures;

      const filesToUpload = validFiles.slice(0, remainingFiles);

      if (filesToUpload.length < validFiles.length) {
        this.showAlert("Error", `You can only upload ${remainingFiles} more images.`);
      }

      const picutres = filesToUpload.map(file => URL.createObjectURL(file))

      this.setState({
        uploadedPictures: [...this.state.uploadedPictures, ...picutres],
        uploadedFiles: [...this.state.uploadedFiles, ...filesToUpload.map(file => new Blob([file], { type: file.type }))]
      })
    }
  }

  handleRemovePicture = (index: number) => {
    const uploadedPicturesCopy = [...this.state.uploadedPictures];
    uploadedPicturesCopy.splice(index, 1);

    const uploadedFilesCopy = [...this.state.uploadedFiles];
    uploadedFilesCopy.splice(index, 1);

    this.setState({
      uploadedPictures: uploadedPicturesCopy,
      uploadedFiles: uploadedFilesCopy
    })
  }

  handleOpenSelectProfilePicture = () => {
    if (this.inputProfilePictureRef) {
      this.inputProfilePictureRef.current?.click();
    }
  }

  goToChangePassword = () => {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationChangePasswordMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  btnGoBack = {
    onPress: () => this.props.navigation.goBack()
  }

  txtInputNameProps = {
    onChangeText: (text: string) => {
      this.setState({ fullName: text });

      //@ts-ignore
      this.txtInputNameProps.value = text;
    },
  };

  txtInputEmailProps = {
    onChangeText: (text: string) => {
      this.setState({ email: text });

      //@ts-ignore
      this.txtInputEmailProps.value = text;
    },
  };

  txtInputStreetProps = {
    onChangeText: (text: string) => {
      this.setState({ street: text });

      //@ts-ignore
      this.txtInputStreetProps.value = text;
    },
  };

  txtInputCityProps = {
    onChangeText: (text: string) => {
      this.setState({ city: text });

      //@ts-ignore
      this.txtInputCityProps.value = text;
    },
  };

  txtInputStateProps = {
    onChangeText: (text: string) => {
      this.setState({ state: text });

      //@ts-ignore
      this.txtInputStateProps.value = text;
    },
  };

  txtInputCountryProps = {
    onChangeText: (text: string) => {
      this.setState({ country: text });

      //@ts-ignore
      this.txtInputCountryProps.value = text;
    },
  };

  txtInputZipcodeProps = {
    onChangeText: (text: string) => {
      this.setState({ zipcode: text });

      //@ts-ignore
      this.txtInputZipcodeProps.value = text;
    },
  };

  txtInputBioProps = {
    onChangeText: (text: string) => {
      this.setState({ bio: text });

      //@ts-ignore
      this.txtInputBioProps.value = text;
    },
  };

  handleUpadateOperatingHoursOpenClose = (value: boolean, day: string) => {
    this.setState({
      operatingHours: {
        ...this.state.operatingHours,
        [day.toLowerCase()]: {
          ...this.state.operatingHours[day.toLowerCase()],
          open: value,
        }
      }
    })
  }

  handleUpdateOperatingHoursStartHour = (value: Date, day: string) => {
    this.setState({
      operatingHours: {
        ...this.state.operatingHours,
        [day.toLowerCase()]: {
          ...this.state.operatingHours[day.toLowerCase()],
          start_hour: value,
        }
      }
    })
  }

  handleUpdateOperatingHoursEndHour = (value: Date, day: string) => {
    this.setState({
      operatingHours: {
        ...this.state.operatingHours,
        [day.toLowerCase()]: {
          ...this.state.operatingHours[day.toLowerCase()],
          end_hour: value,
        }
      }
    })
  }

  formatHour = (date: Date | null) => {
    if (!date) return "null"
    const hour = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");
    return `${hour}:${minutes}:${seconds}`
  }

  txtInputLinkWebsiteProps = {
    onChangeText: (text: string) => {
      this.setState({ linkWebsite: text });

      //@ts-ignore
      this.txtInputLinkWebsiteProps.value = text;
    },
  };

  txtInputFacebookProps = {
    onChangeText: (text: string) => {
      this.setState({ facebook: text });

      //@ts-ignore
      this.txtInputFacebookProps.value = text;
    },
  };

  txtInputInstagramProps = {
    onChangeText: (text: string) => {
      this.setState({ instagram: text });

      //@ts-ignore
      this.txtInputInstagramProps.value = text;
    },
  };

  txtInputPasswordProps = {
    onChangeText: (text: string) => {
      this.setState({ password: text.trim() });

      //@ts-ignore
      this.txtInputPasswordProps.value = text.trim();
    },
    secureTextEntry: true,
  };

  btnPasswordShowHideImageProps = {
    source: imgPasswordVisible,
  };

  btnPasswordShowHideProps = {
    onPress: () => {
      this.setState({ enablePasswordField: !this.state.enablePasswordField });
      this.txtInputPasswordProps.secureTextEntry =
        !this.state.enablePasswordField;
      this.btnPasswordShowHideImageProps.source = this.txtInputPasswordProps
        .secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  navigateToProfile = () => {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationUserProfileMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  updateBusinessInformation = () => {
    const header = {
      token: this.token
    };

    if (this.state.fullName.trim().length === 0) {
      this.showAlert("Error", "field name are mandatory.");
      return false;
    }


    if (this.state.email.trim().length === 0) {
      this.showAlert("Error", "field email are mandatory.");
      return false;
    }

    if (this.state.street.trim().length === 0) {
      this.showAlert("Error", "field street are mandatory.");
      return false;
    }

    if (this.state.city.trim().length === 0) {
      this.showAlert("Error", "field city are mandatory.");
      return false;
    }

    if (this.state.state.trim().length === 0) {
      this.showAlert("Error", "field state are mandatory.");
      return false;
    }

    if (this.state.country.trim().length === 0) {
      this.showAlert("Error", "field country are mandatory.");
      return false;
    }

    this.setState({
      loading: true
    })

    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.updateBusinessInformationCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.updateCustomizableProfileEndPoint}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    const formData = new FormData();

    formData.append('data[attributes]user_name', this.state.fullName);
    formData.append('data[attributes]email', this.state.email);
    formData.append('data[attributes]street', this.state.street);
    formData.append('data[attributes]city', this.state.city);
    formData.append('data[attributes]state', this.state.state);
    formData.append('data[attributes]country', this.state.country);
    formData.append('data[attributes]zipcode', this.state.zipcode);
    formData.append('data[attributes]link_to_website', this.state.linkWebsite);
    formData.append('data[attributes]bio', this.state.bio);
    formData.append('data[attributes]facebook_link', this.state.facebook);
    formData.append('data[attributes]instagram_link', this.state.instagram);

    Object.entries(this.state.operatingHours).forEach(([key, value]) => {
      formData.append(`data[attributes]${key}_open`, String(value.open));
      formData.append(`data[attributes]${key}_start_hour`, this.formatHour(value.start_hour));
      formData.append(`data[attributes]${key}_end_hour`, this.formatHour(value.end_hour));
    })

    if (this.state.uploadedFiles) {
      this.state.uploadedFiles.forEach((file) => {
        formData.append(`data[attributes][images][]`, file);
      })
    }

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPutMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  // Customizable Area End
}
