import React from "react";
// Customizable Area Start
import { View, StyleSheet,TouchableOpacity, TouchableWithoutFeedback, Switch, Platform, Dimensions, ScrollView } from "react-native";
import Popover from "../../../components/src/Popover.web";
import AppHeader from "../../../components/src/AppHeader.web";
import { Typography} from "@builder/component-library";
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined'
import MyLocationOutlinedIcon from '@mui/icons-material/MyLocationOutlined';
import FiltersIcon from '@mui/icons-material/TuneOutlined';
import ArrowDownIcon from '@mui/icons-material/ExpandMoreOutlined';
import LocationIcon from '@mui/icons-material/LocationOnOutlined';
import { shopPin, doPin, eatPin, parkPin, shopIcon, shopIconSelected, eatIcon, eatIconSelected, doIcon, doIconSelected, mePin } from './assets'
import { Marker, Polygon } from "react-native-maps";
import Favourites from "../../favourites/src/Favourites";
import Filteritems from "../../filteritems/src/Filteritems";
import AdvancedSearch from "../../advancedsearch/src/AdvancedSearch";
import StoreLocator from "../../storelocator/src/StoreLocator";
import Gallery from "../../storelocator/src/Gallery.web";

// Customizable Area End

const viewportHeight = Dimensions.get("window").height;
const viewportWidth = Dimensions.get("window").width;

import MapView from "react-native-maps";

import MapsController, { Props, configJSON } from "./MapsController";

export default class Maps extends MapsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    this.getToken();
  }

  getMarkerIconByCategory = (categoryType: string) => {
    switch (categoryType.toLowerCase()) {
      case 'shop':
        return shopPin;
      case 'eat':
        return eatPin;
      case 'do':
        return doPin
      case 'parking':
        return parkPin;
      default:
        return shopPin;
    }
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <View style={styles.container}>
        <Popover 
          testID="popover"
          visible={this.state.popoverVisibility}
          icon={<LocationIcon sx={{fontSize: 24, color: "#8861A9"}} />}
          title="Share your location?"
          subTitle="Do you want to share your location? The link to your location will be copied to your clipboard."
          primaryButtonText="Share location"
          primaryButtonColor="#8861A9"
          secondaryButtonText="Cancel"
          {...this.popoverProps}
        />

        {
          this.state.galleryVisibility && (
            <Gallery 
              {...this.props} 
              photos={this.state.galleryPhotos} 
              onCloseGallery={this.handleCloseGallery} 
            />
          )
        }

        <AppHeader {...this.props} onPressFavourites={this.handleOpenFavourites} />

        <MapView
          ref={this.mapViewRef}
          // @ts-ignore
          options={{
            // mapTypeId: "hybrid",
            mapId: "ed1e5d39a86cc40d",
            // styles: customMapStyle,
            streetViewControl: false,
            fullscreenControl: false,
            mapTypeControl: false,
            showsUserLocation: true,
            showsMyLocationButton: true,
            restriction: this.state.mapLimits ? {
              latLngBounds: this.state.mapLimits,
              strictBounds: false,
            } : null
          }}
          style={styles.map}
          initialRegion={{
            latitude: this.state.currentPosition.coords.latitude || 37.80025,
            longitude: this.state.currentPosition.coords.longitude || -122.4500,
            latitudeDelta: 0.0922,
            longitudeDelta: 0.0421,
          }}
        >
          <View style={styles.mapHeader}>
            {
              this.state.sideSection.isOpen ? (
                <View style={{ width: 380 }} />
              ) : (
                <TouchableWithoutFeedback testID="inputSearchContainer" onPress={this.handleFocusInputSearch}>
                  <View style={styles.inputSearchContainer}>
                    <SearchOutlinedIcon sx={styles.inputSearchIcon} />
                    <input 
                      id="inputSeach"
                      ref={this.inputSearchRef} 
                      style={this.isPlatformWeb() ? {...styles.inputSearch, borderWidth: 0, outlineStyle: "none" } : styles.inputSearch} 
                      placeholder="Search" 
                      value={this.state.inputSearchText}
                      {...this.inputSearchProps}
                    />
                  </View>
                </TouchableWithoutFeedback>
              )
            }
            <View style={styles.headerSection}>
              <TouchableOpacity testID="btnToggleFilters" onPress={this.handleToggleFilters} style={styles.headerFiltersButton}>
                <FiltersIcon sx={styles.headerFiltersIcon} />
              </TouchableOpacity>
            </View>

            <View style={styles.headerSection}>
              <View style={styles.filterContainer}>
                <TouchableOpacity 
                  testID="btnShopFilter"
                  style={this.state.filtering.includes("shop") ? styles.filterButtonSelected : styles.filterButton} 
                  onPress={() => this.onFiltering("shop")}
                >
                  <img src={this.state.filtering.includes("shop") ? shopIconSelected : shopIcon} style={styles.filterButtonIcon} />
                  <Typography 
                    variant="base" 
                    style={this.state.filtering.includes("shop") ? styles.filterButtonTextSelected : styles.filterButtonText}
                  >
                    Shop
                  </Typography>
                </TouchableOpacity>

                <TouchableOpacity 
                  testID="btnEatFilter"
                  style={this.state.filtering.includes("eat") ? styles.filterButtonSelected : styles.filterButton} 
                  onPress={() => this.onFiltering("eat")}
                >
                  <img src={this.state.filtering.includes("eat") ? eatIconSelected : eatIcon} style={styles.filterButtonIcon} />
                  <Typography 
                    variant="base" 
                    style={this.state.filtering.includes("eat") ? styles.filterButtonTextSelected : styles.filterButtonText}
                  >
                    Eat
                  </Typography>
                </TouchableOpacity>

                <TouchableOpacity 
                  testID="btnDoFilter"
                  style={this.state.filtering.includes("do") ? styles.filterButtonSelected : styles.filterButton} 
                  onPress={() => this.onFiltering("do")}
                >
                  <img src={this.state.filtering.includes("do") ? doIconSelected : doIcon} style={styles.filterButtonIcon} />
                  <Typography 
                    variant="base" 
                    style={this.state.filtering.includes("do") ? styles.filterButtonTextSelected : styles.filterButtonText}
                  >
                    Do
                  </Typography>
                </TouchableOpacity>
              </View>
            </View>

            <View style={styles.headerSection}>
              <View style={styles.footerItem}>
                <Typography variant="base" style={styles.footerText}>Open today</Typography>
                <Switch 
                  testID="switchOpenTodayFilter"
                  style={styles.footerSwitch} 
                  thumbColor={"#FFF"}
                  {...Platform.select({web: {
                    activeThumbColor: "white"
                  }})}
                  trackColor={{
                    true: "#8861A9",
                    false: "#AAA"
                  }}
                  value={this.state.openToday}
                  {...this.switchOpenTodayFilterProps}
                />
              </View>
            </View>

            <View style={styles.headerSection}>
              <View style={styles.footerItem}>
                <Typography variant="base" style={styles.footerText}>Favourites</Typography>
                <Switch 
                  testID="switchFavouritesFilter"
                  style={styles.footerSwitch} 
                  thumbColor={"#FFF"}
                  {...Platform.select({web: {
                    activeThumbColor: "white"
                  }})}
                  value={this.state.favourites} 
                  trackColor={{
                    true: "#8861A9",
                    false: "#AAA"
                  }} 
                  {...this.switchFavouritesFilterProps}
                />
              </View>
            </View>
          </View>

          <View style={[styles.sideSectionContainer, this.state.sideSection.isOpen && styles.sideSectionContainerAnimated]}>          

            {
              this.state.sideSection.type === "geofence" && (
                <View style={styles.geofenceContainer}>
                  <View style={styles.geofenceHeader}>
                    <Typography variant="base" style={styles.geofenceText}>5 Miles radius</Typography>
                    <ArrowDownIcon sx={styles.geofenceHeaderIcon} />
                  </View>

                  <View style={styles.table}>
                    <View style={styles.tr}>
                      <View style={styles.tdLocation}>
                        <Typography variant="sm" style={styles.theadText}>Location</Typography>
                      </View>
                      <View style={styles.tdUser}>
                        <Typography variant="sm" style={styles.theadText}>User</Typography>
                      </View>
                      <View style={styles.tdTime}>
                        <Typography variant="sm" style={styles.theadText}>Time</Typography>
                      </View>
                    </View>

                    {
                      Array.from({ length: 5 }).map((_, index) => {
                        return (
                          <View key={index} style={styles.tr}>
                            <View style={styles.tdLocation}>
                              <Typography variant="sm" style={styles.tdText}>United state</Typography>
                            </View>
                            <View style={styles.tdUser}>
                              <Typography variant="sm" style={styles.tdText}>User name</Typography>
                            </View>
                            <View style={styles.tdTime}>
                              <Typography variant="sm" style={styles.tdText}>11:03:01</Typography>
                            </View>
                          </View>
                        )
                      })
                    }
                  </View>
                </View>
              )
            }

            {
              this.state.sideSection.type === 'favourites' && (
                <Favourites {...this.props} handleCloseFavourites={this.handleCloseSideSection} />
              )
            }

            {
              this.state.sideSection.type === "advanceSearch" && (
                <AdvancedSearch 
                  {...this.props} 
                  handleCloseAdvancedSearch={this.handleCloseSideSection}
                  onOpenBusinessDetails={this.handleOpenBusinessDetails}
                  onSearch={this.getStores}
                  stores={this.state.stores}
                  searchText={this.state.inputSearchText}
                />
              )
            }

            {
              this.state.sideSection.type === "filters" && (
                <Filteritems 
                  {...this.props} 
                  handleCloseFilters={this.handleCloseSideSection} 
                  onSaveFilters={this.handleSaveFilters}
                />
              )
            }

            {
              this.state.sideSection.type === "storeLocator" && (
                <StoreLocator 
                  {...this.props} 
                  store={{
                    ...this.state.selectedStore,
                    photos: [
                      "https://images.unsplash.com/photo-1528698827591-e19ccd7bc23d?q=80&w=2676&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
                      "https://plus.unsplash.com/premium_photo-1661964205360-b0621b5a9366?q=80&w=2638&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
                      "https://images.unsplash.com/photo-1482859602406-7659b00979fb?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTF8fHN0b3Jlc3xlbnwwfHwwfHx8MA%3D%3D",
                      "https://images.unsplash.com/photo-1526199119161-4be1e3368d52?q=80&w=2574&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
                      "https://images.unsplash.com/photo-1527323344-e6c837099941?q=80&w=2686&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
                      "https://images.unsplash.com/photo-1465237392552-2f11d0f540d4?q=80&w=2669&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                    ]
                  }}
                  onCloseStoreLocator={this.handleCloseSideSection}
                  onOpenGallery={this.handleOpenGallery}
                />
              )
            }
          </View>

          <TouchableOpacity 
            testID="btnLocateMe" 
            style={this.state.showLocateMe ? {...styles.locateMeButton, backgroundColor: "#8861A9" }: styles.locateMeButton} 
            onPress={this.handleLocateMe}
          >
            <MyLocationOutlinedIcon 
              sx={this.state.showLocateMe ? {...styles.locateMeIcon, color: "#FFF" }: styles.locateMeIcon} 
            />
          </TouchableOpacity>

          {
            (this.state.showLocateMe && this.state.userLocation.coords.latitude && this.state.userLocation.coords.longitude) && (
              <Marker 
                testID="locateMe"
                coordinate={{
                  latitude: this.state.userLocation.coords.latitude,
                  longitude: this.state.userLocation.coords.longitude,
                }}
                icon={mePin}
              />
            )
          }

          {
            this.state.stores.map(store => {
              const markerIcon = this.getMarkerIconByCategory(store.business_category_type[0] || "");

              return (
                <Marker 
                  key={store.id}
                  testID={`marker-${store.id}`}
                  coordinate={{
                    latitude: store.latitude,
                    longitude: store.longitude,
                  }}
                  icon={markerIcon}
                  onPress={() => this.handleOpenBusinessDetails(store)}
                />  
              )
            })
          }

          {
            this.state.cityBoundary && (
              <Polygon
                coordinates={this.state.cityBoundary}
                strokeColor="#5600cc"
                fillColor="transparent"
                strokeWidth={4}
              />   
            )
          }
         
        </MapView>
      </View> 
    );
  }
  // Customizable Area End
}

// Customizable Area Start
const customMapStyle = [
  {
    featureType: "landscape.man_made",
    elementType: "geometry.stroke",
    stylers: [
      {
        color: "#B3A4CF"
      }
    ]
  },
  {
    featureType: "road.arterial",
    elementType: "geometry",
    stylers: [
      { color: "#8861A9" }
    ]
  },
  {
    featureType: "road.highway",
    elementType: "geometry",
    stylers: [
      { color: "#8861A9" }
    ]
  },
  {
    featureType: "road.local",
    elementType: "geometry",
    stylers: [
      { color: "#8861A9" }
    ]
  },
];
 
const styles = StyleSheet.create({
  container: {
    position: "relative",
    flex: 1,
    width: "100%",
    backgroundColor: "#9BCE7B",
    alignItems: "center"
  },
  header: {
    paddingHorizontal: 40,
    paddingVertical: 16,
    width: "100%",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    shadowColor: "#000000",
    shadowOffset: { width: 0, height: 4 },
    shadowOpacity: 0.1,
    shadowRadius: 12,
    zIndex: 99
  },
  headerActions: {
    flexDirection: "row",
    alignItems: "center",
  },
  logo: {
    width: 44,
    height: 44,
  },
  headerButton: {
    width: 44,
    height: 44,
    borderRadius: 100,
    alignItems: "center",
    justifyContent: "center",
    marginLeft: 4
  },
  headerButtonIcon: {
    fontSize: 24,
    color: "#0F172A"
  },
  filterContainer: {
    height: "100%",
    paddingVertical: 10,
    paddingHorizontal: 6,
    borderRadius: 50,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  filterButton: {
    // width: "100%",
    height: "100%",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    marginHorizontal: 6,
    paddingHorizontal: 12,
    paddingVertical: 8
  },
  filterButtonText: {
    fontWeight: "700",
    fontFamily: "Inter",
    color: "#64748B",
    marginLeft: 6
  },
  filterButtonIcon: {
    fontSize: 24,
  },
  filterButtonTextSelected: {
    fontWeight: "700",
    fontFamily: "Inter",
    color: "#8861A9",
    marginLeft: 6
  },
  filterButtonSelected: {
    height: "100%",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#E1CCFF",
    borderRadius: 50,
    borderWidth: 1,
    borderColor: "#C399FF",
    marginHorizontal: 6,
    paddingHorizontal: 12,
    paddingVertical: 8
  },
  map: {
    width: "100%",
    flex: 1,
    position: "relative"
  },
  mapHeader: {
    width: "100%",
    height: 40,
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    paddingVertical: 25,
    paddingHorizontal: 40,
    flexDirection: "row"
  },
  headerSection: {
    height: 60,
    backgroundColor: "#FFF",
    borderRadius: 99,
    marginRight: 20,
  },
  headerFiltersButton: {
    paddingVertical: 16,
    paddingHorizontal: 24,
    alignItems: "center",
    justifyContent: "center"
  },
  headerFiltersIcon: {
    fontSize: 24,
    color: "#1E293B"
  },
  inputSearchContainer: {
    width: "100%",
    maxWidth: 410,
    height: 60,
    backgroundColor: "#FFF",
    borderRadius: 99,
    marginRight: 20,
    flexDirection: "row",
    alignItems: "center",
    paddingHorizontal: 22,
    paddingVertical: 18
  },
  inputSearchIcon: {
    fontSize: 24,
    color: "#1E293B",
  },
  inputSearch: {
    flex: 1,
    marginLeft: 8,
    color: "#1E293B",
    fontSize: 14
  },
  footerText: {
    fontWeight: "400",
    fontFamily: "Inter",
    color: "#0F172A"
  },
  footer: {
    maxWidth: 1100,
    width: "100%",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  footerItem: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    height: "100%",
    paddingVertical: 16,
    paddingHorizontal: 24
  },
  footerSwitch: {
    marginLeft: 12,
  },
  locateMeButton: {
    position: "absolute",
    bottom: 120,
    right: 10,
    width: 42,
    height: 42,
    borderRadius: 58,
    backgroundColor: "#FFF",
    alignItems: "center",
    justifyContent: "center"
  },
  locateMeIcon: {
    fontSize: 22,
    color: "#475569",
  },
  sideSectionContainer: {
    width: 0,
    maxWidth: 0,
    height: "100%",
    backgroundColor: "#9BCE7B",
    position: "absolute",
    top: 0,
    left: 0,
    bottom: 0,
    opacity: 0,
    ...(Platform.OS === 'web' && {
      transition: 'width 0.3s ease, max-width 0.3s ease, opacity 0.3s ease',
    }),
  },
  sideSectionContainerAnimated: {
    opacity: 1,
    width: "100%",
    maxWidth: 375
  },
  geofenceContainer: {},
  geofenceHeader: {
    flexDirection: "row",
    alignItems: "center",
    paddingVertical: 8,
    paddingHorizontal: 16,
    marginTop: 27
  },
  geofenceText: {
    fontFamily: "Inter",
    color: "#000000",
    fontWeight: "500",
    marginRight: 12
  },
  geofenceHeaderIcon: {
    fontSize: 20,
    color: "#000000"
  },
  table: {
    paddingHorizontal: 8
  },
  theadText: {
    fontFamily: "Inter",
    color: "#64748B",
    fontWeight: "700"
  },
  tr: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    paddingVertical: 14,
    paddingHorizontal: 16,
    borderBottomWidth: 1,
    borderBottomColor: "#E2E8F0",
  },
  tdLocation: {
    width: 150
  },
  tdUser: {
    width: 130
  },
  tdTime: {
    width: 80
  },
  tdText: {
    fontFamily: "Inter",
    fontWeight: "400",
    color: "#0F172A"
  }
});
// Customizable Area End
