import React from "react";

import {
  Container,
  Box,
  Input,
  Button,
  InputLabel,
  Typography,
  InputAdornment,
  // Customizable Area Start
  // Customizable Area End
  IconButton,
} from "@mui/material";

// Customizable Area Start
import { View, StyleSheet, TouchableOpacity, ScrollView, Platform, Pressable, Text } from 'react-native'
import { Typography as TypographyBuilder, Button as ButtonBuilder } from "@builder/component-library";
import HeartIcon from '@mui/icons-material/FavoriteBorderOutlined';
import HeartFilledIcon from '@mui/icons-material/FavoriteOutlined';
import LocationIcon from '@mui/icons-material/LocationOnOutlined';
import TimeIcon from '@mui/icons-material/AccessTimeOutlined';
import WebIcon from '@mui/icons-material/PublicOutlined';
import PhoneIcon from '@mui/icons-material/LocalPhone';
import {
  Theme,
} from "@mui/material/styles";
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { facebook, instagram } from './assets'
// Customizable Area End

import StoreLocatorController, {
  Props,
  configJSON,
} from "./StoreLocatorController";
import "@mui/styles";

declare module "@mui/styles/defaultTheme" {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

export default class StoreLocator extends StoreLocatorController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    console.log("STORE", this.props.store)
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <>
        <View style={styles.storeLocatorContainerHeader}>
          <TypographyBuilder style={styles.storeLocatorContainerHeaderTitle} variant="xl">Store locator</TypographyBuilder>

          <View style={styles.storeLocatorContainerHeaderActions}>
            <TouchableOpacity testID="btnCloseStoreLocator" onPress={() => this.props.onCloseStoreLocator()}>
              <CloseOutlinedIcon sx={styles.storeLocatorContainerHeaderIcon} />
            </TouchableOpacity>
          </View>
        </View>

        <ScrollView style={styles.storeLocatorContainer}>
          <View style={{ paddingHorizontal: 24, }}>
          <img 
            src={"https://images.unsplash.com/photo-1528698827591-e19ccd7bc23d?q=80&w=2676&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"} 
            style={styles.businessImage}  
          />

          <View style={styles.header}>
            <TypographyBuilder variant="2xl" style={styles.headerText}>{this.props.store.user_name}</TypographyBuilder>

            {
              this.state.isFavourite ? (
                <Pressable testID="btnUnFavouriteStore" onPress={this.unfavouriteStore}>
                  <HeartFilledIcon sx={styles.headerHeartIcon} />
                </Pressable>
              ) : (
                <Pressable testID="btnFavouriteStore" onPress={this.favouriteStore}>
                  <HeartIcon sx={styles.headerHeartIcon} />
                </Pressable>
              )
            }
            

          </View>

          <TypographyBuilder variant="base" style={styles.reviewsText}>12 reviews</TypographyBuilder>

          <TypographyBuilder variant="base" style={styles.descriptionText}>{this.props.store.bio || ""}</TypographyBuilder>

          <View style={styles.divider} />

          <View style={styles.businessDetailContainer}>
            <LocationIcon sx={styles.businessDetailIcon} />

            <View style={styles.businessDetailContent}>
              <TypographyBuilder variant="base" style={styles.businessDetailText}>{this.props.store.address}</TypographyBuilder>

              <ButtonBuilder
                testID="btnShowOnTheMap"
                text="Show on the map"
                textStyle={styles.showInTheMapButtonText}
                style={styles.showInTheMapButton}
                {...this.btnOpenMap}
              />
            </View>
          </View>

          <View style={styles.businessDetailContainer}>
            <TimeIcon sx={styles.businessDetailIcon} />

            <Text style={styles.businessDetailText}><Text style={styles.businessOpenText}>Open </Text>• Closes at 7pm</Text>
          </View>

          <View style={styles.businessDetailContainer}>
            <WebIcon sx={styles.businessDetailIcon} />

            <TypographyBuilder variant="base" style={styles.businessDetailText}>{this.props.store.link_to_website || ""}</TypographyBuilder>
          </View>

          <View style={styles.businessDetailContainer}>
            <PhoneIcon sx={styles.businessDetailIcon} />

            <TypographyBuilder variant="base" style={styles.businessDetailText}>{this.props.store.phone_number || ""}</TypographyBuilder>
          </View>

          <View style={styles.businessDetailContainer}>
            <img src={facebook} style={styles.socialMediaImage} />

            <TypographyBuilder variant="base" style={styles.businessDetailText}>{this.props.store.facebook_link || ""}</TypographyBuilder>
          </View>

          <View style={styles.businessDetailContainer}>
            <img src={instagram} style={styles.socialMediaImage} />

            <TypographyBuilder variant="base" style={styles.businessDetailText}>{this.props.store.instagram_link || ""}</TypographyBuilder>
          </View>

          <View style={styles.divider} />

          <TypographyBuilder variant="base" style={styles.photosTitle}>Photos</TypographyBuilder>
          </View>
          <ScrollView horizontal={true} style={styles.gallery}>
            {
              this.props.store.photos?.map((photo, index) => {
                return (
                  <Pressable key={index} testID={"btnOpenGallery-"+index} onPress={() => this.props.onOpenGallery(this.props.store.photos!)}>
                    <img style={styles.galleryPhoto} src={photo} />    
                  </Pressable>
                )
              })
            }
          </ScrollView>
        </ScrollView>
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const styles = StyleSheet.create({
  storeLocatorContainerHeader: {
    flexDirection: "row",
    marginTop: 36,
    paddingHorizontal: 24,
    alignItems: "center"
  },
  storeLocatorContainerHeaderTitle: {
    fontWeight: "700",
    fontFamily: "Inter",
    color: "#1E293B"
  },
  storeLocatorContainerHeaderIcon: {
    fontSize: 24,
    color: "#1E293B",
  },
  storeLocatorContainerHeaderActions: {
    flexDirection: "row",
    marginLeft: "auto"
  },
  storeLocatorContainerHeaderActionButton: {
    marginRight: 8
  },
  inputSearchContainer: {
    height: 60,
    backgroundColor: "#FFF",
    borderRadius: 99,
    marginHorizontal: 20,
    marginTop: 26,
    flexDirection: "row",
    alignItems: "center",
    paddingHorizontal: 22,
    paddingVertical: 18
  },
  inputSearchIcon: {
    fontSize: 24,
    color: "#1E293B",
  },
  inputSearch: {
    flex: 1,
    marginLeft: 8,
    color: "#1E293B",
    fontSize: 14
  },
  storeLocatorContainer: {
    marginTop: 26,
  },
  businessImage: {
    width: 327,
    height: 306,
    borderRadius: 16,
  },
  header: {
    marginTop: 30,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between"
  },
  headerText: {
    fontWeight: "700",
    color: "#0F172A"
  },
  headerHeartIcon: {
    fontSize: 24,
    color: "#8861A9"
  },
  reviewsText: {
    marginTop: 8,
    fontWeight: "400",
    color: "#000000"
  },
  descriptionText: {
    marginTop: 4,
    fontWeight: "400",
    color: "#0F172A"
  },
  divider: {
    width: "100%",
    height: 1,
    backgroundColor: "#E2E8F0",
    marginVertical: 16,
  },
  businessDetailContainer: {
    flexDirection: "row",
    marginBottom: 16
  },
  businessDetailIcon: {
    fontSize: 24,
    color: "#8861A9"
  },
  businessDetailContent: {
    flex: 1,
  },
  businessDetailText: {
    fontSize: 16,
    marginLeft: 16,
    fontWeight: "400",
    color: "#0F172A",
    ...Platform.select({
      web: {
        wordBreak: "break-all",
      },
      default: {}
    })
  },
  businessOpenText: {
    color: "#059669"
  },
  socialMediaImage: {
    width: 24,
    height: 24
  },
  showInTheMapButton: {
    height: 32,
    marginLeft: 16,
    backgroundColor: "#E1CCFF",
    marginTop: 8
  },
  showInTheMapButtonText: {
    fontSize: 16,
    color: "#8861A9"
  },
  photosTitle: {
    fontWeight: "700",
    color: "#000000"
  },
  gallery: {
    marginVertical: 16,
    paddingLeft: 24
  },
  galleryPhoto: {
    width: 104,
    height: 136,
    borderRadius: 8,
    marginRight: 8,
    resizeMode: "contain"
  },
  galleryLastPhoto: {
    width: 104,
    height: 136,
    borderRadius: 8,
    marginRight: 24,
  }
});
// Customizable Area End
