import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData, removeStorageData } from "../../../framework/src/Utilities";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  token: string;
  subscription_id: string;
  subscription_name: string;
  title: string;
  sub_title: string;
  success_subscribed: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class SuccessSubscriptionbilling2Controller extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  createSubscriptionCallId: string = ""
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Sta.rt
    // Customizable Area End
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIRequestMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      token: "",
      subscription_id: "",
      subscription_name: "",
      title: "",
      sub_title: "",
      success_subscribed: false,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    // Customizable Area Start
    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      let token = message.getData(getName(MessageEnum.SessionResponseToken));

      this.setState({ token: token });
    }

    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      const errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (apiRequestCallId && responseJson && !responseJson.errors) {
        if (apiRequestCallId === this.createSubscriptionCallId) {
          this.setState({
            title: "Payment Success!",
            sub_title: `You have successfully subscribed for the ${this.state.subscription_name}`,
            success_subscribed: true
          })
          console.log(responseJson);
        } 
      } else {
        this.setState({
          title: "Subscription Faield.",
          sub_title: responseJson.errors || "Something went wrong try again.",
          success_subscribed: false
        })
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();

    this.getStorageInformation();

    const params = new URLSearchParams(window.location.search);
    const subscription_id = params.get('subscription_id');
    const subscription_name = params.get('subscription_name');

    if (!subscription_id || !subscription_name) {
      this.setState({
        title: "Subscription Faield.",
        sub_title: "Subscription is missing! try to purchase again.",
        success_subscribed: false
      })
      return;
    }
    
    this.setState({
      subscription_id,
      subscription_name
    });
  }

  goToLandingPage() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationLandingPageMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);

    removeStorageData("selectedPlan");
    this.send(msg);
  }

  goToCustomisableUserSubscription() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationCustomisableUserSubscriptionsMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);

    removeStorageData("selectedPlan");
    this.send(msg);
  }

  getStorageInformation = async () => {
    const token = await getStorageData("authtoken");

    if (token) {
      this.setState({
        token,
      }, () => this.createSubscription())
  
      const msg: Message = new Message(getName(MessageEnum.SessionSaveMessage));
  
      msg.addData(
        getName(MessageEnum.SessionResponseToken),
        token
      );
  
      this.send(msg);
    }
  }

  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };

  createSubscription = () => {
    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      token: this.state.token,
    };

    if (!this.state.subscription_id || this.state.subscription_id === "") {
      this.showAlert("error", "Subscription id not found.");
      return false;
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.createSubscriptionCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.createSubscriptionEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify({
        id: this.state.subscription_id
      })
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.proceedToPayAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }
  // Customizable Area End
}
